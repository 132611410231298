

import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const TherapistsAndTeachers = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <h3>Therapists & Teachers Seminar</h3>
<em>"Street Kids, Your Kids, Consequences, and Hope"</em><br/>
<p>Teaching Teens to Think About Consequences<br/><br/>

Teens feel invulnerable. But impulsive actions can put them in danger. Children must learn to think in terms of consequences in order to develop ways of thinking constructively. Rabbi Fine’s experience with lost teens in Times Square applies to “regular” teens as well. He has refined methods that help teenagers from across the spectrum understand consequences. They can, and do, learn to trust their own inner moral compass. Throughout his travels, Rabbi Fine has asked thousands of parents and teens about the essential questions they want answered. Drawing on what he learned, Fine has developed a range of discussions and techniques that have proven helpful to professionals across the 
country. <br/><br/>
<hr/>
Rabbi Fine provides training in:
<ul>
<li>The basics of understanding family systems.</li>
<li>The key questions to ask teens and their families in the first sessions to help chart the direction of treatment in order to arrive at a strong working hypothesis for each family.</li>
<li>The different kinds of trouble kids get into according to the family system they come from.</li>
<li>How certain family systems give rise to violence and abuse</li>
<li>The core issues teens raise today and how they relate to the family of origin. Topics include: Drug and alcohol experimentation and abuse, depression, suicidal ideation, sexuality and intimacy, violence, and spirituality and the quest for meaning.</li>
<li>Brief interventive therapy: The teaching of the Miracle Tale and its applications for teenagers.</li>
<li>The quest for deeper meaning and insight: How to bring spiritual teaching and values into therapy and the classroom.</li>
<li>The issues of the wounded healer: Working with personal spiritual issues that turn burn-out into inspiration and growth.</li>
</ul>
</p>
    </Layout>
  )
}

export default TherapistsAndTeachers

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`